/* src/components/Header.module.css */
.header {
  font-family: 'Departure Mono', monospace;
  background-color: var(--color-background);
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}

.navList {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navItem {
  margin: 0 1rem;
}

.navLink {
  display: flex;
  align-items: center;
  color: var(--color-text);
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: var(--color-primary);
}

.navLink svg {
  margin-right: 0.5rem;
}

.themeToggle {
  display: flex;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.versionNumber {
  color: var(--color-primary);
  font-size: 0.75rem;
  margin-left: 0rem;
}

.hamburgerMenu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
}

@media (max-width: 768px) {
  .nav {
    flex-wrap: wrap;
  }

  .hamburgerMenu {
    display: block;
    order: 1;
  }

  .navList {
    display: none;
    flex-direction: column;
    width: 100%;
    order: 3;
  }

  .navList.open {
    display: flex;
  }

  .navItem {
    margin: 0.5rem 0;
  }

  .themeToggle {
    order: 2;
  }

  .logoContainer {
    flex-grow: 1;
  }
}