/* src/components/ToggleTheme.module.css */
.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.toggleButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}