/* src/components/Footer.module.css */
.footer {
  font-family: 'Departure Mono', monospace;
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 1rem 0;
  text-align: center;
}

.socialLinks {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.socialLink {
  color: var(--color-text);
  transition: color 0.3s ease, transform 0.3s ease;
}

.socialLink:hover {
  color: var(--color-primary);
  transform: scale(1.1);
}

.copyright {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .socialLinks {
    flex-wrap: wrap;
  }
}