/* src/pages/Projects.module.css */
.projects {
  padding: 2rem 0;
}

.projectList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.project {
  background-color: var(--color-box-bg);
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: space-between;
}

.project:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(234, 0, 217, 0.3);
}

.icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--color-secondary);
}

.project h2 {
  color: var(--color-primary);
  margin-bottom: 0.5rem;
  font-size: 1.25em;
}

.project p {
  font-size: 0.9em;
  line-height: 1.4;
  flex-grow: 1;
  margin-bottom: 1rem;
}

.typeIcon {
  margin-top: auto;
  color: var(--color-text);
  opacity: 0.7;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .projectList {
    grid-template-columns: 1fr;
  }
}

.projects h1 {
  color: var(--color-primary);
  font-size: 2.5rem;
  margin-bottom: 2rem;
}