/* src/pages/Home.module.css */
.home {
  text-align: center;
  padding: 2rem 0;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: var(--color-box-bg);
  border-radius: 10px;
}

.profileImage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  margin-right: 2rem;
}

.heroContent {
  text-align: left;
}

.heroContent h1 {
  font-size: 2.5em;
  margin-bottom: 0.5rem;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.summary span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.intro {
  font-size: 1.2em;
  max-width: 800px;
  margin: 2rem auto;
}

.sections {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.section {
  background-color: var(--color-box-bg);
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  padding: 2rem;
  width: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(234, 0, 217, 0.3);
}

.section h2 {
  margin-top: 1rem;
}

.section p {
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .profileImage {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .heroContent {
    text-align: center;
  }

  .summary {
    align-items: center;
  }

  .sections {
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 80%;
  }
}