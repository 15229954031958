/* src/pages/Contact.module.css */
.contact {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.socialMedia {
  margin-bottom: 2rem;
}

.lockup {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.socialLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.socialLink {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: none;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  text-decoration: none;
  color: var(--color-primary);
  transition: background-color 0.3s ease;
}

.socialLink:hover {
  background-color: var(--color-primary);
  color: var(--color-background);
}

.socialLink span {
  margin-left: 0.5rem;
}

.divider {
  border: none;
  border-top: 1px solid var(--color-secondary);
  margin: 2rem 0;
}

.chatContainer {
  background-color: var(--color-box-bg);
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  margin-top: 2rem;
  padding: 1rem;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(234, 0, 217, 0.1);
}

.chatLogo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.comingSoonMessage {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1rem;
  background-color: var(--color-box-bg);
  border: 1px solid var(--color-primary);
  border-radius: 10px;
}

.messages {
  height: 300px;
  overflow-y: auto;
  padding: 1rem;
}

.message {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  max-width: 80%;
  word-wrap: break-word;
  font-family: 'Departure Mono', monospace;
}

.user {
  background-color: var(--color-primary);
  color: var(--color-background);
  align-self: flex-end;
  margin-left: auto;
}

.bot {
  background-color: var(--color-secondary);
  color: var(--color-background);
  align-self: flex-start;
}

.inputForm {
  display: flex;
  padding: 1rem;
  border-top: 1px solid var(--color-primary);
}

.input {
  flex-grow: 1;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-secondary);
  border-radius: 20px;
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: 1rem;
  font-family: 'Departure Mono', monospace;
}

.input:focus {
  outline: none;
  border-color: var(--color-primary);
}

.input::placeholder {
  font-family: 'Departure Mono', monospace;
  color: var(--color-text);
  opacity: 0.7;
}

.sendButton {
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sendButton:hover {
  background-color: var(--color-secondary);
}

@media (max-width: 768px) {
  .contact {
    padding: 1rem;
  }
  
  .messages {
    height: 250px;
  }
}

@media (max-width: 450px) {
  .chatLogo {
    width: 50px;
    height: 50px;
  }

  .comingSoonMessage p {
    font-size: 0.8rem;
  }

  .inputForm .input {
    max-width: 70%;
    margin-left: -0.5rem;
    font-size: 0.8rem;
  }
}