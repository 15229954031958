.articlePreview {
    background-color: var(--color-box-bg);
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    padding: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 3rem;
}

.articlePreview:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(234, 0, 217, 0.3);
    cursor: pointer;
}

.articlePreview a {
    color: var(--color-text);
    text-decoration: none;
    transition: color 0.3s ease;
}

.articlePreview h3 {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: var(--color-primary);
}

.articlePreview p {
    margin-bottom: 1rem;
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--color-box-bg);
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fallbackImage {
    background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    text-align: center;
}

.domain {
    font-size: 0.9em;
    color: var(--color-primary);
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    margin: 0;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    color: #ffffff;
    font-weight: bold;
    z-index: 1000;
}